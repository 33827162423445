import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
  Box,
  Modal,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";

const customStyle = makeStyles({
  root: {
    padding: "60px 40px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  '@media (max-width: 319px)': {
    root: { padding: "60px 5px", width: "90%", gap: "10px" },
  },
  '@media (min-width: 320px) and (max-width: 480px)': {
    root: { padding: "60px 5px", width: "90%", gap: "10px" },
  },
  '@media (min-width: 481px) and (max-width: 768px)': {
    root: { padding: "60px 20px", width: "95%", gap: "25px" },
  },
  '@media (min-width: 769px) and (max-width: 1024px)': {
    root: { padding: "70px 30px", width: "100%", gap: "30px" },
  },
});

const Layout = (props) => {
  const {
    loading,
    documents,
    handleViewImage,
    modalOpen,
    setModalOpen,
    imageURL,
    setImageURL,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    machineId
  } = props;

  const [imageNotFound, setImageNotFound] = useState(false);
  const [streamOpen, setStreamOpen] = useState(false);
  const wsRef = useRef(null);
  const canvasRef = useRef(null);
  const classes = customStyle();

  const handleViewImageClick = async (docId) => {
    try {
      const imageURL = await handleViewImage(docId);
      if (imageURL) {
        setImageURL(imageURL);
        setImageNotFound(false);
      } else {
        setImageNotFound(true);
      }
      setModalOpen(true);
    } catch (error) {
      setImageNotFound(true);
      console.error("Error fetching image:", error);
    }
  };

  const handleStreamClick = () => {
    setStreamOpen(true);
  };

  useEffect(() => {
    console.log("machine id is",machineId)
    if (streamOpen) {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.close();
      }
  
      wsRef.current = new WebSocket("wss://pickaagosentry.duckdns.org:8080");
  
      wsRef.current.onopen = () => {
        console.log("WebSocket Connected");
  
        // 1️⃣ Register the React client with its machineId
        wsRef.current.send(JSON.stringify({
          type: "register",
          role: "react",
          machineId: machineId
        }));
  
        // 2️⃣ Tell the server to start streaming for this machineId
        wsRef.current.send(JSON.stringify({
          type: "control",
          command: "start",
          machineId: machineId
        }));
      };
      console.log("start signal sent ")
  
      wsRef.current.onmessage = (event) => {
        if (typeof event.data !== "string") {
          const blob = new Blob([event.data], { type: "image/jpeg" });
          const img = new Image();
          img.src = URL.createObjectURL(blob);
  
          img.onload = () => {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            URL.revokeObjectURL(img.src);
          };
        }
      };
  
      wsRef.current.onerror = (error) => {
        console.error("WebSocket Error:", error);
      };
  
      wsRef.current.onclose = () => {
        console.log("WebSocket Closed");
      };
    }
  
    return () => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        // 3️⃣ Tell server to stop streaming for this machineId
        wsRef.current.send(JSON.stringify({
          type: "control",
          command: "stop",
          machineId: machineId
        }));
        wsRef.current.close();
        console.log("stop signal sent ")

      }
    };
  }, [streamOpen, machineId]);
  

  return (
    <div className={classes.root}>
      <Button onClick={handleStreamClick}>Live Stream</Button>

      <TableContainer component={Paper}
        sx={{
          '@media (max-width: 319px)': { width: "80%" },
          '@media (min-width: 320px) and (max-width: 480px)': { width: "90%" },
          "@media (min-width: 481px) and (max-width: 768px)": { width: "95%" },
          "@media (min-width: 769px) and (max-width: 1024px)": { width: "100%" },
        }}>
        <Table>
          <TableHead style={{ backgroundColor: "#84ade6" }}>
            <TableRow >
              <TableCell>Transaction Time</TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: "#edf2f9" }}>
            {documents
              .slice()
              .sort((a, b) => (b.timestamp?.seconds || 0) - (a.timestamp?.seconds || 0))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((doc) => (
                <TableRow key={doc.id}>
                  <TableCell>{doc.timestamp ? new Date(doc.timestamp.seconds * 1000).toLocaleString() : "No details available"}</TableCell>
                  <TableCell>{doc.id}</TableCell>
                  <TableCell>{doc.message || "No details available"}</TableCell>
                  <TableCell>
                    <Button variant="outlined" onClick={() => handleViewImageClick(doc.id)}>View</Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={documents.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 1,
          textAlign: "center",
          width: 600,
          '@media (max-width: 319px)': { width: "80%" },
          '@media (min-width: 320px) and (max-width: 480px)': { width: "90%" },
          "@media (min-width: 481px) and (max-width: 768px)": { width: "95%" },
          "@media (min-width: 769px) and (max-width: 1024px)": { width: "100%" },
        }}>
          {imageNotFound ? <Typography>No image found</Typography> : imageURL && <img src={imageURL} alt="Document Image" style={{ width: "100%", height: "auto", borderRadius: "8px" }} />}
        </Box>
      </Modal>

      <Modal open={streamOpen} onClose={() => setStreamOpen(false)}>
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 1,
          textAlign: "center",
          width: 700,
          height: 500,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          '@media (max-width: 768px)': { width: "90%", height: "80%" },
        }}>
          <canvas ref={canvasRef} width="640" height="480" style={{ borderRadius: "8px" }} />
        </Box>
      </Modal>
    </div>
  );
};

export default Layout;
