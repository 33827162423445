import { Button, Grid, TextareaAutosize, makeStyles } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TwoInputField from "../../../components/TwoInputField";
import { puserData } from "../../../assets/lib/puser";
import { useMediaQuery } from '@mui/material';

import { CircularLoader } from "../../../components/widgets/CircularLoader";
import { ErrorText } from "../../../components";

const customStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  formContainer: {
    padding: "10px 40px 40px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    flexGrow: "1",
    overflowY: "scroll",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  formLabel: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  formInput: {
    padding: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "18px",
  },
  topForm: {
    display: "flex",
  },
  heading: {
    margin: 0,
    fontSize: "22px",
    marginTop: "5px",
    paddingBottom: "4px",
    borderBottom: "1px solid #B0B0B0",
  },
  actionBtn: {
    display: "flex",
    justifyContent: "end",
  },
  editBtn: {
    display: "flex",
    padding: "10px 40px 5px",
    justifyContent: "end",
  },
  '@media (max-width: 390px)': {
    formContainer: {
      padding: "5px 10px 10px", 
      gap: "10px",              
    },
    formLabel: {
      fontSize: "14px",        
    },
    formInput: {
      padding: "12px",      
      fontSize: "16px",         
    },
    heading: {
      fontSize: "18px",         
    },
  },
});

const Layout = (props) => {
  const {
    isEdit,
    setIsEdit,
    isNewAdd,
    getUserInput,
    userState,
    handleSubmitModal,
    errorFields,
    fetchLoading,
    submitLoading,
    errorOnFetch,
  } = props;
  const classes = customStyle();
  const isPhone = useMediaQuery('(max-width: 390px)');

  return fetchLoading ? (
    <CircularLoader />
  ) : errorOnFetch ? (
    <ErrorText />
  ) : (
    <div className={classes.root}>
      {submitLoading && <CircularLoader isPoistionAbsolute />}
      {!isNewAdd && (
        <div className={classes.editBtn}>
          {!isEdit && (
            <Button
              variant="outlined"
              style={{
                padding: "10px 24px",
                borderRadius: "10px",
              }}
              onClick={() => {
                setIsEdit(true);
              }}
            >
              <span className="flex align-items-center">
                <EditIcon /> Edit Page
              </span>
            </Button>
          )}
        </div>
      )}
      <div className={classes.formContainer}>
        <h3 className={classes.heading}>Puser Details</h3>

        <div className={classes.topForm}>
          <div className="width-80-per">
            <TwoInputField
              content={puserData.userId}
              value={userState.userId}
              secondContent={puserData.userName}
              secondValue={userState.userName}
              getInputData={getUserInput}
              errorFields={errorFields}
            />
            <TwoInputField
              content={puserData.ppin}
              value={userState.ppin}
              secondContent={puserData.ppin_created}
              secondValue={userState.ppin_created}
              getInputData={getUserInput}
              errorFields={errorFields}
            />
             <TwoInputField
              content={puserData.ppin_expiry}
              value={userState.ppin_expiry}
              secondContent={puserData.transactionsCount}
              secondValue={userState.transactionsCount}
              getInputData={getUserInput}
              errorFields={errorFields}
            />
          </div>
          <div className="width-20-per flex-direction-column pd-lt-20 align-items-center">
            <AccountCircleIcon  sx={{ fontSize: isPhone ? '60px' : '130px',color: '#808080', marginLeft:isPhone?"-10px":"inherit",}} />
            {(isEdit || isNewAdd) && (
              <Button
              style={{ fontSize: isPhone ? '5px' : 'inherit', marginLeft:isPhone?"-10px":"inherit",}}
                variant="contained"
                color="primary"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Upload Image
              </Button>
            )}
          </div>
        </div>
        <div className={classes.actionBtn}>
          {(isEdit || isNewAdd) && (
            <Button
              variant="contained"
              style={{
                color: "white",
                backgroundColor: "#00C853",
                minWidth: isPhone ? "140px" : "200px",
                padding: isPhone ? "8px 20px" : "12px 46px",
                borderRadius: isPhone ? "8px" : "10px",
                fontSize: isPhone ? "10px" : "16px", 
              }}
              onClick={handleSubmitModal}
            >
              {isEdit ? "Save Changes" : "Submit"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Layout;
