import React, { useState } from "react";
import { Button, LinearProgress, makeStyles } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { CircularProgress } from "@material-ui/core";
import { CircularLoader } from "../../../components/widgets/CircularLoader";
import { ErrorText } from "../../../components";
import { doc, setDoc } from "firebase/firestore";
import { storage } from "../../../config/firebase.js";
import { db } from "../../../config/firebase";
import { COLLECTION } from "../../../data/constant";
import icon from '../../../assets/Images/profile.png'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { updateDoc } from "firebase/firestore";
import { Timestamp } from "firebase/firestore"; // Make sure this is imported


const customStyle = makeStyles((theme) => ({
root: {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
  margin: "0 auto",
  padding: "20px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
},
leftSide: {
  flex: 1,
  marginRight: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    marginRight: 0,
    marginBottom: "20px",
  },
},
rightSide: {
  flex: 2,
  display: "flex",
  flexDirection: "column",
  marginLeft: "20px",
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
  },
},
box: {
  border: "1px solid #ccc",
  padding: "20px",
  borderRadius: "8px",
  marginBottom: "20px",
  width: "600px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
},
boxLeft: {
  border: "1px solid #ccc",
  padding: "20px",
  borderRadius: "8px",
  marginBottom: "20px",
  width: "300px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
},
boxLeftProfile: {
  border: "1px solid #ccc",
  padding: "20px",
  borderRadius: "8px",
  marginBottom: "20px",
  textAlign: "center",
  width: "300px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
},
userDetails: {
  marginTop: "20px",
  textAlign: "left",
},
userDetailsLeft: {
  marginTop: "20px",
  alignItems: "center",
},
inputPlaceholder: {
  color: "#aaa", // Adjust the color to your preference
},
actionBtn: {
  marginTop: "10px",
},
gap: {
  marginLeft: "10px", // Adjust the margin as needed
},
profileIcon: {
  height: "100px"
},
completionMeterBox: {
  border: "1px solid #ccc",
  padding: "20px",
  borderRadius: "8px",
  marginBottom: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "600px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
},
completionMeter: {
  marginTop: "10px",
  width: "100%",
},
horizontalLine: {
  borderTop: "1px solid #ccc",
  margin: "10px ",
},
inputLabel: {
  // fontWeight: "bold",
  marginRight: "10px",
  marginTop: "10px", // Add margin-top to the labels
},
inputField: {
  marginBottom: "10px", // Add margin-bottom to the input fields
},

// Media Query for Extra Small Screens (<320px)
'@media (max-width: 319px)': {
root: {
  padding: "5px",
},
leftSide: {
  marginRight: "0px",
},
rightSide: {
  marginTop: "-15px",
  width: "90%",
  marginLeft: "-15px",
},
box: {
  marginTop: "-20px",
  width: "90%",
},
boxLeft: {
  marginTop: "-15px",
  width: "95%",
},
boxLeftProfile: {
  width: "95%",
},
completionMeterBox: {
  marginTop: "-15px",
  width: "90%",
  marginLeft: "0px",
},
inputLabel: {
  fontSize: "8px",
},
inputField: {
  fontSize: "14px",
},
profileIcon: {
  height: "80px",
},
},

// Media Query for Small Screens (320px - 480px)
'@media (min-width: 320px) and (max-width: 480px)': {
root: {
  padding: "10px",
},
leftSide: {
  marginRight: "0px",
},
rightSide: {
  marginTop: "-10px",
  width: "78%",
  marginLeft: "-25px",
},
box: {
  width: "95%",
  marginTop: "-15px",
},
boxLeft: {
  width: "95%",
  marginTop: "-10px",
},
boxLeftProfile: {
  width: "95%",
},
completionMeterBox: {
  width: "95%",
},
inputLabel: {
  fontSize: "10px",
},
inputField: {
  fontSize: "16px",
},
profileIcon: {
  height: "90px",
},
},

// Media Query for Medium Screens (481px - 768px)
'@media (min-width: 481px) and (max-width: 768px)': {
root: {
  padding: "15px",
},
leftSide: {
  marginRight: "10px",
},
rightSide: {
  marginTop: "-5px",
  width: "75%",
  marginLeft: "0px",
},
box: {
  width: "95%",
  marginTop: "-10px",
},
boxLeft: {
  width: "90%",
  marginTop: "-5px",
},
boxLeftProfile: {
  width: "90%",
},
completionMeterBox: {
  width: "90%",
},
inputLabel: {
  fontSize: "12px",
},
inputField: {
  fontSize: "17px",
},
profileIcon: {
  height: "95px",
},
},

// Media Query for Large Screens (769px - 1024px)
'@media (min-width: 769px) and (max-width: 1024px)': {
root: {
  padding: "20px",
},
leftSide: {
  width: "75%",

},
rightSide: {
  width: "75%",
  marginLeft:"-30px"
},
box: {
  width: "100%",
},
boxLeft: {
  width: "100%",
},
boxLeftProfile: {
  width: "100%",
},
completionMeterBox: {
  width: "100%",
},
inputLabel: {
  fontSize: "14px",
},
inputField: {
  fontSize: "18px",
},
profileIcon: {
  height: "100px",
},
},
}));


const Layout = ({ userData, userId, loading }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [editedUserData, setEditedUserData] = useState(userData);
  const [profilePicture, setProfilePicture] = useState(null); 
  const classes = customStyle();
  const [isEditingPpin, setIsEditingPpin] = useState(false);

  const handlePpinEditToggle = () => {
    setIsEditingPpin(true);
  };
  
  const handleCancelPpinEdit = () => {
    setIsEditingPpin(false);
    setEditedUserData(prev => ({ ...prev, ppin: userData.ppin }));
  };


const handleSavePpin = async () => {
  try {
    const now = new Date();
    const ppinCreated = Timestamp.fromDate(now);
    const ppinExpiry = Timestamp.fromDate(new Date(now.getTime() + 24 * 60 * 60 * 1000)); // +24 hours

    const userDocRef = doc(db, COLLECTION.PUSERS, userId);
    await updateDoc(userDocRef, {
      ppin: editedUserData.ppin,
      ppin_created: ppinCreated,
      ppin_expiry: ppinExpiry,
    });

    setIsEditingPpin(false);
    console.log("Ppin and timestamps updated successfully!");

    window.location.reload(); // Optional: refresh to reflect changes
  } catch (error) {
    console.error("Error updating Ppin and timestamps:", error);
  }
};

  

  const handleEditToggle = () => {
    setIsEdit((prev) => !prev);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveChanges = async () => {
    try {
      // Validate user data before saving (optional, based on your requirements)
  
      // Update user data in Firestore using updateDoc
      const userDocRef = doc(db, COLLECTION.PUSERS, userId);
      await updateDoc(userDocRef, editedUserData);
      await handleProfilePictureUpload();
      console.log("User data saved successfully!");
  
      setIsEdit(false); // Exit edit mode after saving
      window.location.reload(); 
     
  
    } catch (error) {
      console.error("Error saving user data:", error);
    }
  };
  // console.log(profilePicture)

  const handleProfilePictureUpload = async () => {
    if (!profilePicture) {
      console.error("No image selected for upload.");
      return;
    }
  
    try {
      const imageRef = ref(storage, `userPhotos/${userId}`);
      const uploadTask = uploadBytesResumable(imageRef, profilePicture); // Don't use `await` here
  
      // Wait for the upload to complete
      await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          null, // We can listen for progress here, but it's optional
          (error) => reject(error), // Handle errors
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              console.log("Download URL:", downloadURL);
  
              // Update Firestore
              const userDocRef = doc(db, COLLECTION.PUSERS, userId);
              await setDoc(userDocRef, { photoURL: downloadURL }, { merge: true });
  
              setProfilePicture(null);
              resolve();
            } catch (error) {
              reject(error);
            }
          }
        );
      });
      window.location.reload(); 
  
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  
  
  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    setProfilePicture(file);
  };

  if (loading) {
    return <CircularLoader />;
  }

  if (!userData) {
    return <ErrorText />;
  }


  return (
    
    <div className={classes.root} >
      <div className={classes.leftSide}>
      <div className={classes.boxLeftProfile}  style={{backgroundColor:"#6793cf"}}>
  {  userData.photoURL ? (
    
    <img
      src={userData.photoURL}
      alt="Profile"
      style={{ width: "90px", height: "90px", borderRadius: "50px" }}
    />
  ) : (
    <img className={classes.profileIcon} src={icon} />
  )}
  {isEdit && (
    <input
      type="file"
      accept="image/*"
      onChange={handleProfilePictureChange}
      style={{ marginTop: "10px" }}
    />
  )}
  <div className={classes.userDetailsLeft}>
    <h3>{userData.userName}</h3>
  </div>
</div>

        <div className={classes.boxLeft}  style={{backgroundColor:"#b1ccf1"}}>
        <div className={classes.userDetails} > 
  <h3>Social Media</h3>
  {isEdit ? (
    
    <> 

      <label className={classes.inputLabel}>Twitter</label>
      <input
        type="text"
        name="twitter"
        value={editedUserData.twitter}
        onChange={handleInputChange}
      />
      <br /> 
      <label className={classes.inputLabel}>Instagram</label>
      <input
        type="text"
        name="instagram"
        value={editedUserData.instagram}
        onChange={handleInputChange}
      />
      <br /> 
      <label className={classes.inputLabel}>Facebook</label>
      <input
        type="text"
        name="facebook"
        value={editedUserData.facebook}
        onChange={handleInputChange}
      />
    </>
  ) : (
    <>
      <p>
        Twitter: <a href={userData.twitter}>{userData.twitter}</a>
      </p>
      <hr className={classes.horizontalLine} />
      <p>
        Instagram:{" "}
        <a href={userData.instagram}>{userData.instagram}</a>
      </p>
      <hr className={classes.horizontalLine} />
      <p>
        Facebook:{" "}
        <a href={userData.facebook}>{userData.facebook}</a>
      </p>
    </>
  )}
</div>

        </div>
      </div>
      <div className={classes.rightSide}>
        <div className={classes.box}  style={{backgroundColor:"#84ade6",marginTop:"0px"}}> 
        <div className={classes.userDetails} >
  <h3>User Details</h3>
  {isEdit ? (
    <>
    <div className={classes.inputField}>
      <label className={classes.inputLabel}>Name:</label>
      <input
        type="text"
        name="userName"
        value={editedUserData.userName}
        onChange={handleInputChange}
      />
    </div>
    <div className={classes.inputField}>
      <label className={classes.inputLabel}>Phone Number:</label>
      <input
        type="text"
        name="userId"
        value={editedUserData.userId}
        onChange={handleInputChange}

      />
    </div>
    <div className={classes.inputField}>
      <label className={classes.inputLabel}>PassCode:</label>
      <input
        type="text"
        name="passCode"
        value={editedUserData.passCode}
        onChange={handleInputChange}
      />
    </div>
    <div className={classes.inputField}>
      <label className={classes.inputLabel}>Pin:</label>
      <input
        type="text"
        name="ppin"
        value={editedUserData.ppin}
        onChange={handleInputChange}
      />
    </div>
    <div className={classes.inputField}>
      <label className={classes.inputLabel}>Pin Validity:</label>
      <input
        type="text"
        name="Ppin Expiry"
        value={editedUserData.ppin_expiry?.toDate().toISOString().split("T")[0] || ""} 

        onChange={handleInputChange}
        isEditable={false} 
      />
    </div>
    <div className={classes.inputField}>
      <label className={classes.inputLabel}>Total Transactions:</label>
      <input
        type="text"
        name="Total Transactions"
        value={editedUserData.transactionsCount}
        onChange={handleInputChange}
        isEditable={false} 
      />
    </div>
  </>
  ) : (
    <>
      <p>Name: {userData.userName}</p>
      <hr className={classes.horizontalLine} />
      <p>Phone Number: {userData.userId}</p>
      <hr className={classes.horizontalLine} />
      <p>Passcode: {userData.passCode}</p>
      <hr className={classes.horizontalLine} />
    {/* Ppin section */}
      <p>
        Pin:{" "}
        {isEditingPpin ? (
          <>
            <input
              type="text"
              name="ppin"
              value={editedUserData.ppin}
              onChange={handleInputChange}
            />
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSavePpin}
              className={classes.gap}
            >
              Save 
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleCancelPpinEdit}
              className={classes.gap}
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            <b>{userData.ppin}</b>
            {!isEdit && (
              <Button onClick={handlePpinEditToggle}   className={classes.gap}  size="small">
              <EditIcon /> 
              </Button>
            )}
          </>
        )}
      </p>
      <hr className={classes.horizontalLine} />
      <p>Pin Validity: {userData.ppin_expiry?.toDate().toLocaleString() || "N/A"}</p>
      <hr className={classes.horizontalLine} />
      <p>Total Transactions: {userData.transactionsCount}</p>

    </>
  )}
</div>
        </div>
        <div className={classes.actionBtn}>
            {isEdit ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveChanges}
                style={{marginLeft:"500px",marginBottom:"10px"}}
              >
                Save Changes
              </Button>
            ) : (
              <Button variant="outlined" onClick={handleEditToggle}   style={{marginLeft:"550px",marginTop:"-30px"}} >
                <EditIcon /> Edit
              </Button>
            )}
          </div>
      </div>
    </div>
  );
};

export default Layout;
