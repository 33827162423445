import React from "react";
import Layout from "./Layout";
import { useState } from "react";
import {
  createInitialForm,
  validateRequiredField,
} from "../../../common/common";
import { PloginData } from "../../../assets/lib/plogin";
import { COLLECTION, SESSION } from "../../../data/constant";
import {
  Timestamp,
  addDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routing/routes";

const PuserLogin = () => {
  const [loginState, setLoginState] = useState(createInitialForm(PloginData));
  const [loginFailedText, setLoginFailedText] = useState("");
  const [errorFields, setErrorFields] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const navigate = useNavigate();

  const getLoginInput = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const inputType = event.target.type;
    if (value && inputType === "number") {
      value = parseInt(value);
    }
    if (errorFields.includes(name) && ![undefined, null, ""].includes(value)) {
      setErrorFields((prevState) => prevState.filter((key) => key !== name));
    }
    setLoginState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLogin = async () => {
    const error = validateRequiredField(PloginData, loginState);
    setErrorFields(error);

    if (error.length === 0) {
      try {
        setSubmitLoading(true);
        const collectionRef = collection(db, COLLECTION.PUSERS);
        const baseQuery = query(
          collectionRef,
          where("userId", "==", loginState.phoneNumber)
        );
        const querySnapshot = await getDocs(baseQuery);

        if (!querySnapshot.empty) {
          const docSnap = querySnapshot.docs[0];
          const docData = { ...docSnap.data(), id: docSnap.id };
          if (docData.passCode === loginState.passCode) {
            // setLoginFailedText("Login successfull");
            console.log(loginState.phoneNumber,"login successfull")
            // Ensure userId is sent as a string
            try {
              const response = await fetch(
                "https://us-central1-alpaago-testbench.cloudfunctions.net/managePpin",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ userId: String(loginState.phoneNumber) }), // Convert number to string
                }
              );

              if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
              }

              const responseData = await response.json();
              console.log("API Response:", responseData);
            } catch (apiError) {
              console.error("API Error:", apiError);
            }
            

            const sessionCollectionRef = collection(
              db,
              COLLECTION.PUSER_SESSION
            );
            const addedDocRef = await addDoc(sessionCollectionRef, {
              userId: docData.userId,
              sessionStart: Timestamp.now(),
              userID: docData.id,
            });
            setSubmitLoading(false);
            localStorage.setItem(SESSION.PUSER_SESSION_ID, addedDocRef.id);
            navigate(routes.PUSER_TRANSACTION, {
              state: { userId: docData.userId },
            });
          } else {
            setSubmitLoading(false);
            setLoginFailedText("Incorrect Passcode");
          }
        } else {
          setSubmitLoading(false);
          setLoginFailedText("No phone number found");
        }
      } catch (error) {
        setSubmitLoading(false);
        console.log("error", error);
      }
    }
  };

  return (
    <Layout
      loginState={loginState}
      loginFailedText={loginFailedText}
      errorFields={errorFields}
      getLoginInput={getLoginInput}
      handleLogin={handleLogin}
    />
  );
};

export default PuserLogin;
