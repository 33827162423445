import React from "react";
import Layout from "./Layout";
import { useState } from "react";
import {
  createInitialForm,
  fetchLatestId,
  validateRequiredField,
} from "../../../common/common";
import { signUpData } from "../../../assets/lib/login";
import { COLLECTION, COLLECTION_COUNTER } from "../../../data/constant";
import { addDoc, collection,getDocs,query,where } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routing/routes";
import moment from "moment/moment";
import { useEffect } from "react";

function SignUp() {
  const [signUpState, setSignUpState] = useState(createInitialForm(signUpData));
  const [errorFields, setErrorFields] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [phoneNoNot10Digit, setPhoneNoNot10Digit] = useState(false);
  const [alpinNot4Digit, setAlpinNot4Digit] = useState(false);
  const [isInvalidOtp, setIsInvalidOtp] = useState(false);
  const [otp, setOtp] = useState('');
  const [userData, setUserData] = useState([]);
  const [existingUser, setExistingUser] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const collectionUserRef = collection(db, COLLECTION.USER);
      const baseUserQuery = query(collectionUserRef);

      const dataUserSnapshot = await getDocs(baseUserQuery);

      const collectionUserData = dataUserSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setUserData(collectionUserData);
    };
    fetchUserData()
  }, []);
  
  const navigate = useNavigate();

  const getSignUpInput = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const inputType = event.target.type;
    if (value && inputType === "number") {
      value = parseInt(value);
    }
    if (errorFields.includes(name) && ![undefined, null, ""].includes(value)) {
      setErrorFields((prevState) => prevState.filter((key) => key !== name));
    }
    setSignUpState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmitSignUp = async () => {
    const error = validateRequiredField(signUpData, signUpState);
    setErrorFields(error);

    if (signUpState.otp !== Number(otp)) {
      setIsInvalidOtp(true)
      return;
    }

    if (error.length === 0) {
      setSubmitLoading(true);
      try {
        const userId = await fetchLatestId(COLLECTION_COUNTER.USER);
        const userDetails = {
          alpin: signUpState.newAlpin,
          phoneNumber: signUpState.phoneNumber,
          createdTimestamp: moment().unix(),
          hashedalpin: "",
          no: userId,
          walletAmount: 50,
          userName: signUpState.userName,
          goCoins:0
        };

        const userCollectionRef = collection(db, COLLECTION.USER);
        await addDoc(userCollectionRef, userDetails);

        setSubmitLoading(false);
        navigate(routes.USER_LOGIN);
      } catch (error) {
        setSubmitLoading(false);
        console.log("error", error);
      }
    }
  };


  const sendOTP = async (otp, phoneNumber) => {
    const response = await fetch(`https://2factor.in/API/V1/df62b83f-22ee-11ee-addf-0200cd936042/SMS/${phoneNumber}/${otp}/OTP1`);
    if (!response.ok) {
      throw new Error('Failed to send OTP');
    }
    return response.json();
  };

  const generateOTP = () => {
    return Math.floor(1000 + Math.random() * 9000).toString();
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const isValidAlpin = (alpin) => {
    const alpinRegex = /^\d{4}$/;
    return alpinRegex.test(alpin);
  };

const isExistingUser =  (phoneNumber) => {
  const usrArray = userData;  
  // Check if any user's phone number matches the input phone number
  const isMatch = usrArray.some(user => user.phoneNumber === phoneNumber);
  // Return false if a match is found, true if no match is found
  return !isMatch;
};



  const handleOtpSendClick = async () => {
    let error = validateRequiredField(signUpData, signUpState);
    // Filtering out the otp as it will be check while submit button is clicked
    error = error.filter(item => item !== 'otp')
    setErrorFields(error);

    if (isValidPhoneNumber(signUpState.phoneNumber)) {
      console.log("Phone",isValidPhoneNumber(signUpState.phoneNumber))
      setPhoneNoNot10Digit(false);
    } else {
      setPhoneNoNot10Digit(true);
    }

    if (isValidAlpin(signUpState.newAlpin)) {
      console.log("Alpin",isValidAlpin(signUpState.newAlpin))
      setAlpinNot4Digit(false);
    } else {
      setAlpinNot4Digit(true);
    }
    if (isExistingUser(signUpState.phoneNumber)) {
      console.log("user",isExistingUser(signUpState.phoneNumber))
      setExistingUser(false);
    } else {
      setExistingUser(true);
    }


    if (error.length === 0 && signUpState.phoneNumber && isValidPhoneNumber(signUpState.phoneNumber) && isValidAlpin(signUpState.newAlpin) && isExistingUser(signUpState.phoneNumber)) {
      const generatedOtp = generateOTP();
      setOtp(generatedOtp);
      sendOTP(generatedOtp, signUpState.phoneNumber);
    }
  }

  return (
    <Layout
      submitLoading={submitLoading}
      signUpState={signUpState}
      otp={otp}
      isInvalidOtp={isInvalidOtp}
      phoneNoNot10Digit={phoneNoNot10Digit}
      alpinNot4Digit={alpinNot4Digit}
      errorFields={errorFields}
      getSignUpInput={getSignUpInput}
      handleSubmitSignUp={handleSubmitSignUp}
      handleOtpSendClick={handleOtpSendClick}
      existingUser={existingUser}
      
    />
  );
}

export default SignUp;
