import React, { useEffect, useState } from "react";
import { db, storage } from "../../../config/firebase";
import { getDownloadURL, ref } from "firebase/storage";
import { COLLECTION, SESSION } from "../../../data/constant";
import { doc, getDoc,getDocs,where,query ,collection} from "firebase/firestore";

import Layout from "./Layout";

const PUserTransaction = () => {
  const [documents, setDocuments] = useState([]); // Firestore documents
  const [loading, setLoading] = useState(true);
  const [imageURL, setImageURL] = useState(null); // Image URL for modal
  const [modalOpen, setModalOpen] = useState(false); // Modal visibility
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [machineId, setMachineId] = useState(null);


  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        // Retrieve session ID from local storage
        const sessionId = localStorage.getItem(SESSION.PUSER_SESSION_ID);
        if (!sessionId) {
          throw new Error("Session ID not found");
        }
  
        // Get user information based on session
        const sessionRef = doc(db, COLLECTION.PUSER_SESSION, sessionId);
        const sessionSnap = await getDoc(sessionRef);
  
        if (!sessionSnap.exists()) {
          throw new Error("Session data not found");
        }
  
        const { userId } = sessionSnap.data();
        //console.log("User ID:", userId);
  
        // Query Ppods collection to find the document with matching userId
        const ppodsCollectionRef = collection(db, "Ppods");
        const ppodQuery = query(ppodsCollectionRef, where("userId", "==", userId));
        const ppodSnapshot = await getDocs(ppodQuery);
  
        if (ppodSnapshot.empty) {
          throw new Error("No matching Ppod found for the user");
        }
  
        // Assume we use the first matching Ppod document
        const ppodData = ppodSnapshot.docs[0].data();
        const { ppodNumber } = ppodData;
        setMachineId(ppodNumber)
  
        // Query Ptransactions collection to find documents with matching ppodNumber
        const transactionsCollectionRef = collection(db, "Ptransactions");
        const transactionQuery = query(
          transactionsCollectionRef,
          where("podNumber", "==", ppodNumber),
          where("auth", "==", "Auth1")
        );
        const transactionSnapshot = await getDocs(transactionQuery);
  
        const docs = transactionSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        setDocuments(docs);
      } catch (error) {
        console.error("Error fetching documents:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchDocuments();
  }, []);
  

  // Fetch image from Firebase Storage
  const handleViewImage = async (id) => {
    try {
      const imageRef = ref(storage, `PpodImages/${id}.jpg`);
      const url = await getDownloadURL(imageRef);
      console.log("Fetched Image URL:", url);
      return url; // Return the URL so Layout.js can handle it
    } catch (error) {
      console.error("Error fetching image:", error);
      return null; // Return null if there's an error
    }
  };
  

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Layout
      loading={loading}
      documents={documents}
      handleViewImage={handleViewImage}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      imageURL={imageURL}
      setImageURL={setImageURL} 
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      machineId={machineId}
    />
  );
};

export default PUserTransaction;
